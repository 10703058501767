import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import {getJurisdictionName} from "../../utils/functions";

import Button from "@/components/button";
import Input from "@/components/input";
import Table from "@/components/table";
import NeoTabsSwitch from "@/components/tabs-switch";
import Loader from "@/components/loader";
import NeoAnalyse from "@/components/analyse";

// import axios from "../../../../axios/osint.js";

export default {
    name: "domain-company-details-section",
    components: {
        "neo-input": Input,
        "neo-button": Button,
        "neo-table": Table,
        "neo-tabs-switch": NeoTabsSwitch,
        "font-awesome-icon": FontAwesomeIcon,
        "neo-loader": Loader,
        NeoAnalyse,
    },
    props: {
        domainCompanyDetailsSearch: {
            type: Array,
            default: Object,
        },
    },
    data() {
        return {
            // domainCompanyDetailsSearch: {},
            loaderController: {
                loading: false,
                success: false,
                filterLoading: false,
                filterSuccess: false,
            },
            case_id: "",
        };
    },
    computed: {},
    methods: {
        getJurisdictionDisplayName(code) {
            return getJurisdictionName(code);
        },
        startLoader() {
            this.loaderController.success = false;
            this.loaderController.loading = true;
        },
        successLoader() {
            this.loaderController.success = true;
            this.loaderController.loading = false;
        },
        resetLoader() {
            this.loaderController.success = false;
            this.loaderController.loading = false;
        },
    },
};
