import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import Button from "@/components/button";
import Input from "@/components/input";
import Table from "@/components/table";
import NeoTabsSwitch from "@/components/tabs-switch";
import Loader from "@/components/loader";
import NeoAnalyse from "@/components/analyse";
import toolsDisclaimer from "@/components/tools-disclaimer";
export default {
    name: "domain-section",
    components: {
        "neo-input": Input,
        "neo-button": Button,
        "neo-table": Table,
        "neo-tabs-switch": NeoTabsSwitch,
        "font-awesome-icon": FontAwesomeIcon,
        "neo-loader": Loader,
        NeoAnalyse,
        toolsDisclaimer,
    },
    props: {
        domainLinkedInUserDetails: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            // domainLinkedInUserDetails: {},
            loaderController: {
                loading: false,
                success: false,
                filterLoading: false,
                filterSuccess: false,
            },
            case_id: "",
        };
    },
    computed: {},
    methods: {
        startLoader() {
            this.loaderController.success = false;
            this.loaderController.loading = true;
        },
        successLoader() {
            this.loaderController.success = true;
            this.loaderController.loading = false;
        },
        resetLoader() {
            this.loaderController.success = false;
            this.loaderController.loading = false;
        },
    },
};
