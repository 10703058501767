import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import Button from "@/components/button";
import Input from "@/components/input";
import Table from "@/components/table";
import NeoTabsSwitch from "@/components/tabs-switch";
import Loader from "@/components/loader";
// import axios from "../../../../axios/osint.js";
import analyse from "@/components/analyse";
export default {
    name: "domain-company-section",
    components: {
        "neo-input": Input,
        "neo-button": Button,
        "neo-table": Table,
        "neo-tabs-switch": NeoTabsSwitch,
        "font-awesome-icon": FontAwesomeIcon,
        "neo-loader": Loader,
        analyse,
    },
    props: {
        domainCompanyDetails: {
            type: Object,
            default: Object,
        },
    },
    data() {
        return {
            // domainCompanyDetails: {},
            loaderController: {
                loading: false,
                success: false,
                filterLoading: false,
                filterSuccess: false,
            },
            case_id: "",
        };
    },
    computed: {},
    methods: {
        startLoader() {
            this.loaderController.success = false;
            this.loaderController.loading = true;
        },
        successLoader() {
            this.loaderController.success = true;
            this.loaderController.loading = false;
        },
        resetLoader() {
            this.loaderController.success = false;
            this.loaderController.loading = false;
        },
    },
};
